// 个人中心
import request from '@/utils/request.js';

// 消息中心--条数
export const getNotesCount = () => {
  return request({
    method: 'get',
    url: '/notes/datacount',
    headers: {
      token: true
    },
  })
}

// 消息中心
export const getNotes = (type, page) => {
  return request({
    method: 'get',
    url: '/notes',
    headers: {
      token: true
    },
    params: {
      type,
      page
    }
  })
}
// 消息中心--已读
export const setReadmsg = (id) => {
  return request({
    method: 'get',
    url: '/readmsg',
    headers: {
      token: true
    },
    params: {
      id
    }
  })
}

// 监控列表
export const getMonitorList = ({ associate_type, keywords, page }) => {
  return request({
    method: 'get',
    url: '/usermonitorlist',
    headers: {
      token: true
    },
    params: {
      associate_type,
      keywords,
      page
    }
  })
}

// 监控日报
export const getDailyList = (page) => {
  return request({
    method: 'get',
    url: '/notes/list',
    headers: {
      token: true
    },
    params: {
      page
    }
  })
}
// 监控日报详情--列表
export const getDailyDetailsList = (data) => {
  return request({
    method: 'post',
    url: '/notes/details',
    headers: {
      token: true
    },
    data
  })
}
// 监控日报详情--主体
export const getDailyDetailsCon = (id) => {
  return request({
    method: 'post',
    url: '/paper/details',
    headers: {
      token: true
    },
    data: {
      id
    }
  })
}

// 下载列表
export const getReportList = (page) => {
  return request({
    method: 'get',
    url: '/userreport',
    headers: {
      token: true
    },
    params: { page }
  })
}

// 报告生成
export const getReportDownload = (params) => {
  return request({
    method: 'get',
    url: '/download',
    headers: {
      token: true
    },
    params
  })
}

// 重新生成报告
export const getReportDownloadAgain = (id) => {
  return request({
    method: 'post',
    url: '/afreshreport',
    headers: {
      token: true
    },
    data: {
      id
    }
  })
}

// 报告删除
export const delReport = (id) => {
  return request({
    method: 'post',
    url: '/delreport',
    headers: {
      token: true
    },
    data: {
      id
    }
  })
}
