/** 消息中心 */
<template>
  <div class="notice-content">
    <el-tabs type="card" v-model="activeTab">
      <!-- 监控通知 -->
      <el-tab-pane class="notice-main mt-48" name="2">
        <el-badge slot="label" :value="notesCount.uncount" :max="99">
          监控通知
        </el-badge>
        <ul v-if="monitoringList.length" class="notice-monitoring notice-list">
          <li class="mt-10 back-fff" v-for="(item,index) in monitoringList" :key="index">
            <div class="mb-10 justify-between-center">
              <h4 class="notice-title">监控通知 <span class="notice-date">{{item.message_time}}</span></h4>
              <router-link :to="{path:'/personal/daily-details',query:{id:item.id}}">
                <el-button type="text" class="notice-con-more pa-0">查看详情</el-button>
              </router-link>
            </div>
            <div class="justify-between-center">
              <p class="notice-con text-ellipsis">{{item.title}}</p>
            </div>
            <i class="notice-dot" v-if="!item.status"></i>
          </li>
        </ul>
        <el-empty v-else description="暂无监控通知消息"></el-empty>
        <Page class="mb-20" :total="notesCount.count" @changePage="changePage" />
      </el-tab-pane>
      <!-- 系统通知 -->
      <el-tab-pane class="notice-main mt-48" name="1">
        <el-badge slot="label" :value="notesCount.unsystem" :max="99">
          系统通知
        </el-badge>
        <ul v-if="systemList.length" class="notice-monitoring notice-list">
          <li class="mt-10 back-fff" v-for="(item,index) in systemList" :key="index">
            <div class="mb-10 justify-between-center">
              <h4 class="notice-title">系统通知</h4>
              <span class="notice-date">2021-11-01</span>
            </div>
            <div class="justify-between-center">
              <div class="notice-con">
                <p v-show="!item.show_all" class="text-ellipsis"> {{item.title}}</p>
                <el-collapse-transition>
                  <div v-show="item.show_all">
                    <p>{{item.title}}</p>
                  </div>
                </el-collapse-transition>
              </div>
              <el-button v-show="!item.show_all" type="text" class="notice-con-more pa-0"
                @click="changeUnfold(item.id,index)">
                展开全部<i class="el-icon-arrow-down"></i>
              </el-button>
            </div>
            <i class="notice-dot" v-if="!item.status"></i>
          </li>
        </ul>
        <el-empty v-else description="暂无系统通知消息"></el-empty>
        <Page class="mb-20" :total="notesCount.system" @changePage="changePage" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import Page from '@/components/Page.vue'
import { getNotesCount, getNotes, setReadmsg } from '@/api/personal'
export default {
  name: 'Notice',
  components: {
    Page
  },
  data () {
    return {
      activeTab: '2',
      notesCount: {},
      monitoringList: [],//监控通知
      systemList: [],//系统通知
    }
  },
  mounted () {
    this.getNotesCount()
    this.getNotes(2)
  },
  methods: {
    // 消息中心--条数
    async getNotesCount () {
      try {
        let { data, status } = await getNotesCount()
        if (status == 200) {
          this.notesCount = data
        }
      } catch (error) {
        return false
      }
    },
    // 消息中心--列表
    async getNotes (type, page = 1) {
      try {
        let { data, status } = await getNotes(type, page)
        if (status == 200) {
          if (type == 2) {
            this.monitoringList = data
          } else {
            this.systemList = data
            this.systemList.forEach(item => {
              item['show_all'] = false
            })
          }
        }
      } catch (error) {
        return false
      }
      if (type == 2 && page == 1) {
        this.getNotes(1, 1)
      }
    },
    // 消息已读
    async setReadmsg (id) {
      try {
        let { data } = await setReadmsg(id)
      } catch (error) {
        return false
      }
    },
    // 监控通知切换分页
    changePage (page) {
      getNotes(this.activeTab, page);
    },
    // 系统通知--展开更多
    changeUnfold (id, ind) {
      if (!this.systemList[ind].status) {
        this.setReadmsg(id)
        this.notesCount.unsystem -= 1
      }
      this.$set(this.systemList, ind, { ...this.systemList[ind], show_all: true, status: 1 })
    }
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-tabs {
  .el-tabs__header {
    .el-tabs__nav-wrap,
    .el-tabs__nav-scroll {
      overflow: inherit;
    }
    .el-tabs__nav .el-tabs__item {
      margin: 0 10px;
    }
    .el-badge {
      .el-badge__content.is-fixed {
        top: -10px;
        right: -5px;
      }
    }
  }
}

.notice-list {
  li {
    padding: 25px 40px;
    position: relative;
  }
}
.notice-title {
  font-size: 18px;
}
.notice-date {
  font-size: 14px;
  color: #999999;
  font-weight: 400;
  margin-left: 10px;
}
.notice-con {
  width: 80%;
  color: #8490ad;
  p {
    width: 100%;
  }
}
.notice-con-more {
  border: 0;
  height: 16px;
}
.notice-dot {
  position: absolute;
  top: 30px;
  left: 20px;
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
}
</style>